@import 'Ease/assets/theme';
@import 'Recharge/assets/theme';
@import 'Select/assets/theme';
@import 'SomeoneHealth/assets/theme';
@import 'Portobello/assets/theme';
@import 'assets/theme';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fcf7f7;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 16px;
  color: #343434;
  cursor: pointer;

  .infoWrapper {
    display: flex;
    flex-direction: column;

    .title {
      font-weight: bold;
      font-size: 16px;
      padding-bottom: 2px;

      @media (max-width: $medium-screen-min-width) {
        font-size: 14px;
      }
    }

    .desc {
      font-size: 16px;
      padding-bottom: 2px;
    }

    .time {
      font-size: 14px;
    }
  }

  .arrowIcon {
    border: 2px solid #2a4670;
    color: #2a4670;
    padding: 4px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 20px;
  }
}

.noLinkContainer {
  @extend .container;
  background: #f3f3f3;
  color: #afadad;
  cursor: not-allowed;

  .arrowIcon {
    border: 2px solid #afadad;
    color: #afadad;
  }
}

.completeContainer {
  @extend .container;
  background: #edfcf4;
  cursor: auto;

  .infoWrapper {
    .title {
      font-size: 16px;
    }

    .desc {
      font-weight: bold;
      color: #3f52ff;
    }

    .time {
      font-size: 14px;
    }
  }

  .successIcon {
    border: 3px solid #4abd03;
    border-radius: 50%;
    font-size: 22px;
    color: #4abd03;
    font-weight: bold;
    padding: 4px;
    background: #fff;
  }
}

:global(.ease-theme) {
  .container {
    .arrowIcon {
      padding: 16px;
      font-size: 24px;
      color: $ease-dark-grey;
      border: 1px solid $ease-green;
      border-radius: 0;
    }
  }

  .noLinkContainer {
    .arrowIcon {
      border: 1px solid #afadad;
      color: #afadad;
    }
  }
}

:global(.recharge-theme) {
  .container {
    border-radius: 4px;
    .arrowIcon {
      padding: 16px;
      font-size: 24px;
      color: $recharge-purple;
      border: 1px solid $recharge-purple;
      border-radius: 4px;
    }
  }

  .noLinkContainer {
    .arrowIcon {
      border: 1px solid #afadad;
      color: #afadad;
    }
  }
}

:global(.select-theme) {
  .container {
    background-color: $select-light-grey-100;
    border-radius: 0;
    box-shadow: 0px 4px 8px 0px #0000001a;

    .arrowIcon {
      padding: 16px;
      font-size: 24px;
      color: $select-black;
      border: 1px solid $select-black;
      border-radius: 0;
    }
  }

  .noLinkContainer {
    .arrowIcon {
      border: 1px solid #afadad;
      color: #afadad;
    }
  }
}

:global(.s1h-client-portal-theme) {
  .container {
    border-radius: 16px;
    color: $someone-health-maroon;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    padding: 24px 40px;
    background-color: $white;

    .title {
      font-size: 24px;
      font-weight: 500;
      line-height: 36px;
    }

    .desc {
      font-size: 18px;
    }

    .arrowIcon {
      padding: 16px;
      font-size: 24px;
      color: $someone-health-maroon;
      border: 1px solid $someone-health-maroon;
      border-radius: 50%;
    }
  }

  .noLinkContainer {
    .arrowIcon {
      border: 1px solid #afadad;
      color: #afadad;
    }
  }
}

:global(.portobello-theme) {
  .container {
    .arrowIcon {
      padding: 16px;
      font-size: 16px;
      color: $portobello-blue;
      border: 1px solid $portobello-blue;
      border-radius: 4px;
      font-weight: 600;
    }
  }
}
