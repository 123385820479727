@import 'assets/theme';
@import 'CaW/assets/theme';
@import 'Ease/assets/theme';
@import 'Recharge/assets/theme';
@import 'Select/assets/theme';
@import 'SomeoneHealth/assets/theme';
@import 'Portobello/assets/theme';

.container {
  height: max-content;
  min-height: 100vh;
  background: linear-gradient(0deg, #fff, #4b6184, #2a4670 40%) no-repeat;
  position: relative;
  z-index: 1;

  .createBtn {
    position: fixed;
    bottom: 40px;
    background: $colorBlue1;
    border-color: $colorBlue1;
    box-shadow: 0 4px 10px rgba(52, 52, 52, 0.35);
    border-radius: 55px !important;
    height: 55px;
    z-index: 4;
    font-weight: 600;
    font-size: 13px;
    color: #fff;
    min-height: 48px;
    min-width: 175px;

    @media (max-height: 740px) {
      bottom: 13px;
      left: 2%;
    }

    .newJournalBtn {
      display: flex;
      align-items: center;

      .icon {
        padding-right: 8px;
      }
    }

    .btnLoading {
      display: flex;
      align-items: center;
      justify-content: center;

      .btn:after {
        border-color: #fff transparent #fff transparent;
      }
    }
  }

  .headerWrapper {
    display: flex;
    padding: 0 0 24px;
  }

  .contentWrapper {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    position: relative;
    z-index: 3;
  }

  .content {
    display: flex;
    flex-direction: column;
    color: #fff;
    padding: 16px;
    padding-bottom: 120px;

    .loading {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .contentBox {
      display: flex;
      flex-direction: column;
      row-gap: 32px;

      .backBtnWrapper {
        display: flex;
        align-items: center;
        font-size: 16px;

        .backBtn {
          display: flex;
          align-items: center;
          cursor: pointer;

          .icon {
            font-size: 28px;
            margin-left: -8px;
            line-height: 21px;
          }
        }
      }

      .taskListContainer {
        display: flex;
        flex-direction: column;

        .taskListTitle {
          font-size: 18px;
          line-height: 28px;
          color: #fff;
          padding-bottom: 24px;

          @media (max-height: $medium-screen-min-height) {
            font-size: 16px;
          }

          .heading {
            font-weight: 600;
            font-size: 24px;
            line-height: 28px;
            padding-bottom: 24px;
          }
        }

        .taskListBox {
          display: flex;
          flex-direction: column;
          row-gap: 24px;
          max-width: 600px;

          @media (max-height: $medium-screen-min-height) {
            row-gap: 16px;
          }
        }
      }
    }
  }

  .footer {
    z-index: 3;
  }
}

.container::after {
  position: absolute;
  content: ' ';
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;
  height: 100%;
  min-height: 100vh;
  opacity: 0.8;
  background-color: #2a4670;
}

.shContainer {
  @extend .container;
  background-size: cover;
  background-image: url('~SomeoneHealth/assets/images/background/background.png');

  &::after {
    background-color: transparent;
  }

  .content {
    color: $someone-health-maroon;
    .contentBox {
      .taskListContainer {
        .taskListTitle {
          color: $someone-health-maroon;
        }
      }
    }
  }
}

.cawContainer {
  @extend .container;
  @include loginBackground;

  &::after {
    background-color: transparent;
  }

  .content {
    color: $caw-blue;
    .contentBox {
      .taskListContainer {
        .taskListTitle {
          color: $caw-blue;
        }
      }
    }
  }
}

.easeContainer {
  @extend .container;
  background: none;

  &::after {
    background: none;
  }

  .content {
    .contentBox .taskListContainer .taskListTitle {
      color: $ease-dark-grey;

      .heading {
        color: $ease-dark-purple;
      }
    }

    .contentBox .backBtnWrapper .backBtn {
      color: $blue500;
    }
  }
}

.rechargeContainer {
  @extend .container;
  background: none;

  &::after {
    background: none;
  }
}

.selectContainer {
  @extend .container;
  background: none;

  &::after {
    background: none;
  }

  .content {
    .contentBox .taskListContainer .taskListTitle {
      color: $select-black;

      .heading {
        color: $select-black;
      }
    }

    .contentBox .backBtnWrapper .backBtn {
      color: $select-black;
    }
  }
}

.portobelloContainer {
  @extend .container;
  min-height: calc(100vh - 82px);
  background-size: cover;
  background-image: url('~Portobello/assets/images/background/background.png');
  background-repeat: no-repeat;
  font-family: $Playfair-Display;

  &::after {
    background: none;
    min-height: auto;
  }

  .portobelloContentLayout {
    max-width: 1440px;
  }
}
