@import 'assets/theme';

.container {
  margin-top: 96px;

  @media (max-width: $medium-screen-min-width) {
    margin-top: 68px;
  }
}

.form {
  background-color: white;
  background-image: url(~assets/images/background-circles.png);
  background-size: 100%;
  background-repeat: no-repeat;
  padding: $standard-padding * 9 $standard-padding * 4 $standard-padding * 6;
  border-radius: $standard-padding * 2 $standard-padding * 2 0 0;

  @media (max-width: $medium-screen-min-width) {
    padding: 42px $standard-padding * 2 16px;
  }

  @media (min-width: ($medium-screen-min-width + 1)) {
    border-radius: $standard-padding * 2;
  }
}

.cords {
  .form {
    background-image: none;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 24px;

    .direction {
      font-weight: 400;
      font-size: 20px;
      margin-bottom: 16px;
    }
  }

  &.container {
    margin: 52px auto 96px auto;
    max-width: 1040px;
  }
}

:global(.someone-health-theme) {
  .form {
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
    background-image: none;
  }
}

:global(.ease-theme) {
  .form {
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
    background-image: none;
  }
}

:global(.recharge-theme) {
  .form {
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
    background-image: none;
  }
}

:global(.select-theme) {
  .form {
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
    background-image: none;
  }
}

:global(.portobello-theme) {
  .form {
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
    background-image: none;
  }
}

:global(.s1h-client-portal-theme) {
  .form {
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
    background-image: none;
  }
}
