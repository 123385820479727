@import 'Ease/assets/theme';
@import 'Recharge/assets/theme';
@import 'Select/assets/theme';
@import 'SomeoneHealth/assets/theme';
@import 'Portobello/assets/theme';
@import 'assets/theme';

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #ffffffc0;
  position: fixed;
  z-index: 2;
}

.container {
  .formContainer {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 16px 4px;
    width: 100%;

    .title {
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: #343434;
      padding-bottom: 16px;
    }

    .fieldWrapper {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      max-width: 600px;

      .fieldBoxContainer {
        display: flex;
        flex-direction: column;
        flex: 1;

        .fieldTitle {
          font-size: 14px;
          line-height: 23px;
          color: #192a3e;
          padding-bottom: 8px;
        }

        .fieldTextArea {
          width: 100%;
          height: 100px;
          border-radius: 8px;
          padding: 10px;
          border: 1px solid #c1c1c1;
          color: #000000;
        }

        .fieldErrorTextArea {
          @extend .fieldTextArea;
          border: 1px solid #ff4d4f;
        }

        .isReferredByGp {
          padding-bottom: 8px;
        }

        .fieldContainer {
          display: flex;
          flex-direction: column;
          flex: 1;
          max-width: 500px;
        }

        .fieldError {
          font-size: 12px;
          color: #ff4d4f;

          input {
            border-bottom: 1px solid #ff4d4f !important;
          }
        }
      }

      .referredDetailContainer {
        display: flex;
        flex-direction: column;
        padding: 16px 0;
        row-gap: 24px;
      }

      .uploadReferralContainer {
        margin-bottom: $standard-padding;

        .uploadContainerInput {
          display: none;
        }

        .uploadContainerBtn {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 210px;
          padding: $standard-padding;
          border-radius: 4px;
          cursor: pointer;
          background-color: transparent;
          border: 1px solid #3f52ff;
          color: #3f52ff;
          font-weight: 400;

          .icon {
            margin-right: 4px;
          }

          &.error {
            border: 1px solid #fc8468;
            background: #fc8468;
            color: #fff;
          }

          .publishIcon {
            padding-right: 4px;
          }

          .publishLabel {
            font-size: 13px;
            font-weight: 600;
          }
        }
      }

      .referralTitle {
        color: #818e9b;
        font-size: 11px;
        font-weight: 600;
        padding-bottom: 2px;
      }

      .selectedFile {
        color: #34343480;

        .icon {
          margin-left: 4px;
          color: #c0000080;
          cursor: pointer;
        }
      }

      .questionContainer {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        padding: 8px;

        .medicationSelect {
          padding: 0;
        }
      }
    }
  }

  .submitButtonContainer {
    display: flex;
    justify-content: center;
    padding: 40px 0 8px;

    @media (max-height: $medium-screen-min-height) {
      padding: 16px 0 8px;
    }

    .submitBtn {
      min-width: 250px;
    }
  }

  .hr {
    width: 100%;
    border: none;
    border-bottom: solid 1px;
    border-bottom-color: #f3f3f3;
    margin: 8px 0;
    max-width: 600px;
  }
}

:global(.ease-theme) {
  .container .formContainer .fieldWrapper .uploadReferralContainer .uploadContainerBtn {
    width: 240px;
    border-color: $ease-green;
    color: $ease-dark-grey;
    text-transform: uppercase;
  }
}

:global(.recharge-theme) {
  .container .formContainer .fieldWrapper .uploadReferralContainer .uploadContainerBtn {
    width: 240px;
    border-color: $recharge-purple;
    color: $recharge-purple;
  }
}

:global(.select-theme) {
  .container .formContainer .fieldWrapper .uploadReferralContainer .uploadContainerBtn {
    width: 240px;
    border-color: $select-red;
    color: $select-black;
  }
}

:global(.portobello-theme) {
  .container .formContainer .fieldWrapper .uploadReferralContainer .uploadContainerBtn {
    width: 240px;
    border-color: $select-red;
    color: $portobello-black;
  }
}

:global(.s1h-client-portal-theme) {
  .container .formContainer .fieldWrapper {
    .fieldBoxContainer {
      .fieldTitle,
      .fieldTextArea {
        color: $someone-health-maroon;
      }
    }

    .uploadReferralContainer .uploadContainerBtn {
      width: 240px;
      border-color: $someone-health-maroon;
      color: $someone-health-maroon;
    }
  }
}
