@import 'assets/theme';
@import 'SomeoneHealth/assets/theme';
@import 'CaW/assets/theme';
@import 'Ease/assets/theme';
@import 'Recharge/assets/theme';
@import 'Select/assets/theme';
@import 'Portobello/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  color: #fff;
  position: relative;
  z-index: 3;
  min-height: 100vh;

  .contentWrapper {
    display: flex;
    flex-direction: column;

    .wrapper {
      background-color: transparent !important;
      padding: 0 16px;
    }
  }

  .headerContainer {
    width: 100%;

    @media (max-width: $medium-large_screen-min-width) {
      padding: 0 16px;
    }

    .helmHeader {
      padding: 0 0 16px 0;
    }

    .shHeader {
      padding: 8px 0 !important;

      @media (max-width: $medium-large_screen-min-width) {
        padding: 8px 16px !important;
      }
    }

    .tacklitHeader {
      padding: 8px 0 !important;

      @media (max-width: $medium-large_screen-min-width) {
        padding: 8px 16px !important;
      }
    }
  }

  .header {
    padding: 16px 16px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .profilePic {
      height: 100px;
      width: 100px;
    }

    .logout {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-top: $standard-padding * 2;
      margin-right: $standard-padding;

      span {
        margin-left: 4px;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 180px;
    padding: 20px 0;
    font-size: 18px;

    @media (max-width: $medium-large_screen-min-width) {
      padding: 20px 16px;
    }

    .title {
      font-size: 28px;
      font-weight: 600;
    }

    .clinicianInfo {
      display: flex;
      align-items: center;

      .clinicianAvatar {
        max-width: 80px;
        margin-top: 16px;
        margin-right: 24px;
        margin-bottom: 16px;
        margin-left: 8px;
        border-radius: 50%;
      }

      .clinicianName {
        font-size: 32px;
        font-weight: 600;
      }
    }

    p {
      margin-top: $standard-padding * 2;
    }

    .signature {
      font-weight: 600;
    }

    .homePageBtnWrapper {
      display: flex;
      width: 100%;
      justify-content: center;

      .homePageBtn {
        display: flex;
        color: #343434;
        margin-top: 16px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }

  .card {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    background-color: $white;
    background-image: url(~assets/images/background-circles.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 180px;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;

    .returnContent {
      width: 100%;

      .title {
        margin: 32px 24px 0;
        font-size: 15px;
        text-align: center;
        letter-spacing: 0.1px;
        color: #3f52ff;
      }

      .buttonWrapper {
        margin-bottom: 16px;
      }

      .button {
        width: 75%;
        margin: auto;
        margin-top: $standard-padding * 3;
      }
    }
  }
}

:global(.caw-theme).container {
  color: $caw-blue !important;

  .title {
    color: $caw-blue !important;
  }
}

:global(.ease-theme).container {
  color: $ease-dark-grey !important;

  .title {
    color: $ease-dark-grey !important;
  }
}

:global(.recharge-theme).container {
  .card {
    background-image: none;

    .returnContent .title {
      color: $recharge-black;
    }
  }
}

:global(.select-theme).container {
  color: $select-black !important;

  .title {
    color: $ease-dark-grey !important;
  }

  .card {
    background-image: none;

    .returnContent .title {
      color: $select-black;
    }
  }
}

:global(.portobello-theme).container {
  color: $white !important;

  .subtitle {
    color: $portobello-black !important;
  }

  .card {
    background-image: none;

    .returnContent .title {
      color: $portobello-black;
    }
  }
}

:global(.s1h-client-portal-theme) {
  .container {
    color: $someone-health-maroon;

    .title {
      color: $someone-health-maroon;
    }

    .card {
      background-image: none;

      .returnContent .title {
        color: $someone-health-maroon;
      }
    }
  }

  .headerContainer {
    .shHeader {
      @media (max-width: $medium-large_screen-min-width) {
        padding: 8px 0 !important;
      }
    }
  }
}
